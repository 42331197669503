import { PlayerWorld } from '~app/types/player-world.type';
import { requestSecure } from '~app/utils/request';

export const fetchList = async (): Promise<PlayerWorld[]> => {
    return requestSecure<PlayerWorld[]>('/worlds-list', { method: 'GET' });
};

export const create = async (data: Partial<PlayerWorld>): Promise<PlayerWorld> => {
    return requestSecure<PlayerWorld>('/worlds', { method: 'POST', data });
};
