import React, { useCallback, useMemo, useRef } from 'react';

import { TOURNAMENT_PRIZE_IMAGES } from '~app/assets';
import bg5 from '~app/assets/img/bg5.jpg';
import { Empty } from '~app/components/empty';
import { Share } from '~app/components/share';
import { useTournamentsContext } from '~app/contexts';
import { ITournamentPrizesModalRef, TournamentPrizesModal } from '~app/modals/tournament-prizes.modal';
import { IPrize, PrizeType } from '~app/types/prize.interface';

interface ITournamentPrizes {
    canEdit: boolean;
}

export const TournamentPrizes = ({ canEdit }: ITournamentPrizes): JSX.Element => {
    const { tournament } = useTournamentsContext();

    const { standardPrizes, specialPrizes, secondaryPrizes, isNoPrizes } = useMemo(() => {
        const _specialPrizes: IPrize[] = [];
        const _secondaryPrizes: IPrize[] = [];
        const _standardPrizes: IPrize[] = [];

        if (tournament?.prize_one) {
            _standardPrizes.push({
                id: 1,
                money: tournament.prize_one.toString(),
                description: '1st place prize',
                type_id: PrizeType.STANDARD,
            });
        }

        if (tournament?.prize_two) {
            _standardPrizes.push({
                id: 1,
                money: tournament.prize_two.toString(),
                description: '2nd place prize',
                type_id: PrizeType.STANDARD,
            });
        }

        if (tournament?.prize_three) {
            _standardPrizes.push({
                id: 1,
                money: tournament.prize_three.toString(),
                description: '3rd place prize',
                type_id: PrizeType.STANDARD,
            });
        }

        if (tournament?.prize_four) {
            _standardPrizes.push({
                id: 1,
                money: tournament.prize_four.toString(),
                description: '4th place prize',
                type_id: PrizeType.STANDARD,
            });
        }

        if (tournament?.prizes?.length) {
            tournament.prizes.forEach((prize) => {
                switch (prize.type_id) {
                    case PrizeType.SECONDARY: {
                        _secondaryPrizes.push(prize);
                        break;
                    }
                    // case PrizeType.STANDARD: {
                    //     _standardPrizes.push(prize);
                    //     break;
                    // }
                    case PrizeType.SPECIAL: {
                        _specialPrizes.push(prize);
                        break;
                    }
                }
            });
        }

        return {
            standardPrizes: _standardPrizes,
            specialPrizes: _specialPrizes,
            secondaryPrizes: _secondaryPrizes,
            isNoPrizes: _standardPrizes.length === 0 && _specialPrizes.length === 0 && _secondaryPrizes.length === 0,
        };
    }, [tournament]);

    const tournamentPrizesModalRef = useRef<ITournamentPrizesModalRef>(null);

    const onClickEditPrizesHandler = useCallback(() => {
        if (tournament !== null) {
            tournamentPrizesModalRef.current?.open(tournament);
        }
    }, [tournament]);

    return (
        <section className="section section--main section--sm">
            <div className="section-bg">
                <div className="section-bg__img" style={{ backgroundImage: `url(${bg5})` }}></div>
            </div>
            <div className="section-inner">
                <div className="container--sm">
                    {canEdit && (
                        <div className="filter filter--admin">
                            <div className="filter-main">
                                <div className="filter-wrap">
                                    <div className="filter-inner">
                                        <div className="filter-btn">
                                            <button className="btn btn--sm" onClick={onClickEditPrizesHandler}>
                                                edit prizes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!isNoPrizes && (
                        <div className="filter-content">
                            {!!standardPrizes.length && (
                                <div className="content-block">
                                    <h6 className="content-block__title">Standard Prizes</h6>
                                    <div className="prizes--sm">
                                        {standardPrizes.map((prize, index) => {
                                            return (
                                                <div className="prize" key={prize.id}>
                                                    <picture>
                                                        <source
                                                            srcSet={TOURNAMENT_PRIZE_IMAGES[index].webp}
                                                            type="image/webp"
                                                        />
                                                        <source srcSet={TOURNAMENT_PRIZE_IMAGES[index].img} />
                                                        <img
                                                            className="prize-img"
                                                            src={TOURNAMENT_PRIZE_IMAGES[index].img}
                                                            alt=""
                                                        />
                                                    </picture>
                                                    <div className="prize-inner">
                                                        <div className="prize-content">
                                                            <div className="h4 prize-value">{prize.money}</div>
                                                            <div className="h6 prize-place">{prize.description}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}

                            {!!specialPrizes.length && (
                                <div className="content-block">
                                    <h6 className="content-block__title">special prizes</h6>
                                    <div className="prizes--sm">
                                        {specialPrizes.map((prize, index) => {
                                            return (
                                                <div className="prize" key={prize.id}>
                                                    <picture>
                                                        <source
                                                            srcSet={TOURNAMENT_PRIZE_IMAGES[(index % 14) + 4].webp}
                                                            type="image/webp"
                                                        />
                                                        <source
                                                            srcSet={TOURNAMENT_PRIZE_IMAGES[(index % 14) + 4].img}
                                                        />
                                                        <img
                                                            className="prize-img"
                                                            src={TOURNAMENT_PRIZE_IMAGES[(index % 14) + 4].img}
                                                            alt=""
                                                        />
                                                    </picture>
                                                    <div className="prize-inner">
                                                        <div className="prize-content">
                                                            <div className="h4 prize-value">{prize.money}</div>
                                                            <div className="h6 prize-place">{prize.description}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}

                            {!!secondaryPrizes.length && (
                                <div className="content-block">
                                    <h6 className="content-block__title">secondary prizes</h6>
                                    <div className="prizes--sm">
                                        {secondaryPrizes.map((prize) => {
                                            return (
                                                <div className="prize" key={prize.id}>
                                                    <div className="prize-inner">
                                                        <div className="prize-content">
                                                            <div className="h4 prize-value">{prize.money}</div>
                                                            <div className="h6 prize-place">{prize.description}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {isNoPrizes && (
                        <div className="filter-content">
                            <Empty />
                        </div>
                    )}

                    {canEdit && (
                        <div className="filter filter--admin">
                            <div className="filter-main">
                                <div className="filter-wrap">
                                    <div className="filter-inner">
                                        <div className="filter-btn">
                                            <button className="btn btn--sm" onClick={onClickEditPrizesHandler}>
                                                edit prizes
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Share />
                </div>
            </div>
            <TournamentPrizesModal ref={tournamentPrizesModalRef} />
        </section>
    );
};
