import { Bracket } from '~app/types/bracket.type';
import { requestSecure } from '~app/utils/request';

const BASE_PATH = 'brackets';

export const fetchOne = async (id: number): Promise<Bracket> => {
    return requestSecure<Bracket>(`${BASE_PATH}/${id}`, { method: 'GET' });
};

export const create = async (data: any): Promise<{ success: boolean; bracketId: number }> => {
    return requestSecure<{ success: boolean; bracketId: number }>(`${BASE_PATH}`, { method: 'POST', data });
};

export const remove = async (id: number): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${id}`, { method: 'DELETE' });
};

export const update = async (id: number, data: any): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${id}`, { method: 'PUT', data });
};

export const getParticipants = async (id: number): Promise<{ id: number; class?: number }[]> => {
    return requestSecure<{ id: number; class?: number }[]>(`${BASE_PATH}/${id}/participants`, { method: 'GET' });
};

export const getTableSoloParticipants = async (id: number): Promise<number[]> => {
    return requestSecure<number[]>(`${BASE_PATH}/${id}/table-participants`, { method: 'GET' });
};

export const getRelegationParticipants = async (id: number): Promise<number[]> => {
    return requestSecure<number[]>(`${BASE_PATH}/${id}/relegation-participants`, { method: 'GET' });
};

export const updateRelegationFillFirstRound = async (
    id: number,
    participants: number[],
): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${id}/relegation-fill-first-round`, {
        method: 'POST',
        data: { participants },
    });
};

export const updateRelegationRound = async (
    bracketId: number,
    roundId: number,
    data: any,
): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${bracketId}/relegation-rounds/${roundId}`, {
        method: 'POST',
        data,
    });
};

export const updateRelegationDuel = async (
    bracketId: number,
    roundId: number,
    duelId: number,
    data: any,
): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(
        `${BASE_PATH}/${bracketId}/relegation-rounds/${roundId}/duels/${duelId}`,
        {
            method: 'POST',
            data,
        },
    );
};

export const updateTableSoloParticipants = async (
    id: number,
    participants: number[],
): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${id}/table-participants`, {
        method: 'POST',
        data: { participants },
    });
};

export const updateTableSoloCell = async (
    id: number,
    rowId: number,
    columnId: number,
    value: string,
): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${id}/table-cell`, {
        method: 'POST',
        data: { rowId, columnId, value },
    });
};

export const updateTableWight = async (id: number, rowId: number, value: number): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${id}/table-wight`, {
        method: 'POST',
        data: { rowId, value },
    });
};

export const updateGroupsParticipants = async (
    id: number,
    participants: number[],
    distributePlayersByClass: boolean,
): Promise<number[]> => {
    return requestSecure<number[]>(`${BASE_PATH}/${id}/groups/participants`, {
        method: 'POST',
        data: {
            participants,
            distributePlayersByClass,
        },
    });
};

export const updateGroupDuel = async (
    bracketId: number,
    groupId: number,
    duelId: number,
    data: any,
): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${bracketId}/groups/${groupId}/duels/${duelId}`, {
        method: 'POST',
        data,
    });
};

export const updateGroupParticipants = async (
    bracketId: number,
    groupId: number,
    participants: number[],
): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${bracketId}/groups/${groupId}/participants`, {
        method: 'POST',
        data: { participants },
    });
};

export const replaceParticipants = async (
    bracketId: number,
    groupId: number,
    oldParticipant: number | null,
    newParticipant: number | null,
): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${bracketId}/groups/${groupId}/replace-participants`, {
        method: 'POST',
        data: { oldParticipant, newParticipant },
    });
};
