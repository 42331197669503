import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { isArray } from 'lodash';
import moment from 'moment';

import { IAuthorizeResponse } from '~app/types/authorize.interface';

export interface HttpError {
    error?: string;
    message?: string | string[];
}

export interface CustomRequestConfig {
    noRedirectOnErrorResponse?: boolean;
}

export function parseJSON<T>(response: AxiosResponse<T>) {
    return response.data;
}

export async function checkStatus<T extends HttpError>(response: AxiosResponse<T>) {
    if (response && response.status && response.status >= 200 && response.status < 300) {
        return response;
    }

    const errorData = response.data instanceof Blob ? JSON.parse(await response.data.text()) : response.data;

    if (response.status === 401) {
        throw response;
    }

    if (isArray(errorData.message)) {
        throw errorData.message.join(', ');
    }

    throw errorData;
}

async function refreshTokens() {
    const refreshToken = localStorage.getItem('refresh_token');

    if (refreshToken === null) {
        throw new Error('Not authorized');
    }

    const decoded = jwtDecode(refreshToken);
    const expDate = decoded.exp ? moment(decoded.exp * 1000) : null;
    if (expDate === null || moment().isAfter(expDate)) {
        throw new Error('Refresh token is expired');
    }

    const authInfo = await request<IAuthorizeResponse>('/refresh-token', {
        method: 'POST',
        headers: { 'refresh-token': refreshToken },
    });

    localStorage.setItem('access_token', authInfo.access_token);
    localStorage.setItem('refresh_token', authInfo.refresh_token);
}

async function checkAccessToken(url: string) {
    try {
        const accessToken = localStorage.getItem('access_token');

        if (accessToken === null) {
            await refreshTokens();

            return;
        }

        const decoded = jwtDecode(accessToken);
        const expDate = decoded.exp ? moment(decoded.exp * 1000) : null;
        if (expDate === null || moment().isAfter(expDate)) {
            await refreshTokens();
        }
    } catch (err) {
        console.error(url, err);

        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');

        window.location.reload();

        throw err;
    }
}

const BASE_URLS: { [key: string]: string } = {
    prod: 'https://39f9rd9bx7.execute-api.us-east-1.amazonaws.com/prod/api',
    dev: 'https://s6q9s1t1o4.execute-api.us-east-1.amazonaws.com/dev/api',
    default: '/api',
};

export async function request<T>(url: string, config?: AxiosRequestConfig & CustomRequestConfig): Promise<T> {
    const baseURL = BASE_URLS[process.env.REACT_APP_ENV ?? 'default'] ?? BASE_URLS.default;

    return axios(url, {
        ...config,
        baseURL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...config?.headers,
        },
        onUploadProgress: config?.onUploadProgress,
    })
        .then(async (data) => {
            const response = await checkStatus(data);

            return parseJSON(response) as T;
        })
        .catch(async (data) => {
            const res = data.response;
            const response: any = await checkStatus(res);

            return parseJSON(response);
        });
}

export async function requestSecure<T>(url: string, _config?: AxiosRequestConfig & CustomRequestConfig): Promise<T> {
    await checkAccessToken(url);

    const config = _config ?? {};
    const headers = config.headers ? { ...config.headers } : {};
    headers['Access-Token'] = localStorage.getItem('access_token');
    config.headers = headers;

    return request(url, config);
}
