import moment from 'moment/moment';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { PropsValue } from 'react-select';

import { useRootContext } from '~app/contexts/RootContext';
import { ChampionOrder, IChampion, SELECT_CHAMPIONS_ORDER_OPTIONS } from '~app/types/champion.interface';

import { request } from '../utils';

interface IChampionFilter {
    search: string;
    type: { value: number; label: string };
    order: { value: ChampionOrder; label: string };
    dateBegin: Date | null;
}

interface ChampionsContextProps {
    champions: IChampion[] | null;
    fetchChampions: () => Promise<void>;
    filter: IChampionFilter;
    updateFilter: (
        field: keyof IChampionFilter,
        value: string | Date | PropsValue<{ value: number; label: string }> | null,
    ) => void;
    clearChampionsData: () => void;
}

const DEFAULT_FILTER_STATE = {
    search: '',
    type: { value: -1, label: 'All' },
    order: SELECT_CHAMPIONS_ORDER_OPTIONS[0],
    dateBegin: null,
};

export const ChampionsContext = createContext<ChampionsContextProps>({} as ChampionsContextProps);

export const ChampionsContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { showLoader } = useRootContext();

    const [originalChampions, setOriginalChampions] = useState<IChampion[] | null>(null);
    const [champions, setChampions] = useState<IChampion[] | null>(null);
    const [filter, setFilter] = useState<IChampionFilter>(DEFAULT_FILTER_STATE);

    const doFilterChampions = (f: IChampionFilter, oChampions: IChampion[] | null) => {
        if (!oChampions) {
            setChampions(null);

            return;
        }

        const filteredChampions = oChampions
            .filter((_) => {
                if (f.search && _.player?.nick) {
                    return _.player.nick.toLowerCase().includes(f.search.toLowerCase());
                }

                if (f.search && _.team?.name) {
                    return _.team?.name.toLowerCase().includes(f.search.toLowerCase());
                }

                return true;
            })
            .filter((_) => {
                if (f.type.value !== -1) {
                    return _.tournament.type?.id === f.type.value;
                }

                return true;
            })
            .filter((_) => {
                if (filter.dateBegin) {
                    return moment(_.tournament.date).isSame(moment(filter.dateBegin).utc(true));
                }

                return true;
            })
            .sort((a, b) => {
                switch (filter.order.value) {
                    case ChampionOrder.DATE: {
                        if (moment(a.tournament.date).isSame(moment(b.tournament.date))) {
                            return 0;
                        }

                        return moment(a.tournament.date).isAfter(moment(b.tournament.date)) ? -1 : 1;
                    }
                    case ChampionOrder.POOL: {
                        return (a.tournament.pool || -1) - (b.tournament.pool || -1);
                    }
                    case ChampionOrder.TYPE: {
                        return (a.tournament.type?.id || -1) - (b.tournament.type?.id || -1);
                    }
                    case ChampionOrder.STATUS: {
                        return (a.tournament.status || -1) - (b.tournament.status || -1);
                    }
                    case ChampionOrder.TITLE: {
                        if (!a.tournament.title || !b.tournament.title) {
                            return 0;
                        }

                        if (a.tournament.title < b.tournament.title) {
                            return -1;
                        }

                        if (a.tournament.title > b.tournament.title) {
                            return 1;
                        }

                        return 0;
                    }
                    default: {
                        return 0;
                    }
                }
            });

        setChampions(filteredChampions);
    };

    const fetchChampions = async () => {
        showLoader();
        setOriginalChampions(null);
        const res = await request<IChampion[]>('/champions');
        setOriginalChampions(res);
    };

    const updateFilter = (
        field: keyof IChampionFilter,
        value: string | Date | PropsValue<{ value: number; label: string }> | null,
    ) => {
        setFilter((prev) => {
            const newVal = { ...prev };
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            newVal[field] = value;

            return newVal;
        });
    };

    const clearChampionsData = () => {
        setOriginalChampions(null);
        setChampions(null);
        setFilter(DEFAULT_FILTER_STATE);
    };

    useEffect(() => {
        doFilterChampions(filter, originalChampions);
    }, [filter, originalChampions]);

    return (
        <ChampionsContext.Provider
            value={{
                champions,
                fetchChampions,
                filter,
                updateFilter,
                clearChampionsData,
            }}>
            {children}
        </ChampionsContext.Provider>
    );
};

export const useChampionsContext = () => useContext(ChampionsContext);
