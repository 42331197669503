import dragscroll from 'dragscroll';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';

import styles from '~app/components/tournament-bracket-group/tournament-bracket-group.module.scss';
import { BracketRelegationRound } from '~app/components/tournament-bracket-relegation/bracket-relegation-round';
import { useRootContext, useTournamentsContext } from '~app/contexts';
import { Participant } from '~app/types/participant.type';
import { ISelectOption } from '~app/types/select-option.interface';
import API from '~app/utils/apis';
import { useVisibility, VisibilityType } from '~app/utils/hooks/use-visibility.hook';

interface ITournamentBracketRelegationProps {
    canEdit: boolean;
    onClickInsertParticipants: () => void;
}

export const TournamentBracketRelegation = ({
    canEdit,
    onClickInsertParticipants,
}: ITournamentBracketRelegationProps): JSX.Element | null => {
    const { profile } = useRootContext();
    const { currentBracket, currentRoundId, setCurrentRoundId, tournament } = useTournamentsContext();

    const [isInViewportBracketsArea, bracketsAreaRef] = useVisibility<HTMLDivElement>(VisibilityType.HORIZONTAL, 10);

    const [bracketParticipants, setBracketParticipants] = useState<number[]>([]);

    const participantsOptions = useMemo<ISelectOption<Participant>[]>(() => {
        const participants = tournament?.participants ?? [];

        return Object.keys(participants).map((playerId) => ({
            label: participants[+playerId].player?.nick || participants[+playerId].team?.name || '',
            value: participants[+playerId].id,
            data: { ...participants[+playerId] },
        }));
    }, [tournament, bracketParticipants]);

    const rounds = useMemo(() => {
        if (!currentBracket) {
            return [];
        }

        switch (currentRoundId) {
            case 'main-bracket': {
                return currentBracket.roundsMain?.rounds || [];
            }
            case 'defeat-bracket': {
                return currentBracket.roundsDefeat?.rounds || [];
            }
            case 'grand-bracket': {
                return currentBracket.roundsGrand?.rounds || [];
            }
            default: {
                return [];
            }
        }
    }, [currentBracket, currentRoundId]);

    const onClickGrandFinalHandler = useCallback(() => {
        setCurrentRoundId('grand-bracket');
    }, [setCurrentRoundId]);

    useEffect(() => {
        const init = async () => {
            if (currentBracket && profile !== null) {
                setBracketParticipants(await API.Brackets.getRelegationParticipants(currentBracket.id));
            }
        };

        init().then();
    }, [currentBracket, profile]);

    if (!currentBracket || !tournament) {
        return null;
    }

    return (
        <>
            {currentRoundId === 'main-bracket' && canEdit && (
                <div className="container--sm">
                    <div
                        className={styles.groupControl}
                        style={{
                            textAlign: 'right',
                            width: '100%',
                            height: '59px',
                            padding: '0 16px',
                        }}>
                        <div className={styles.groupControl_divider} />
                        <button className="btn btn--sm" style={{ margin: '0 8px' }} onClick={onClickInsertParticipants}>
                            Insert participants
                        </button>
                    </div>
                </div>
            )}
            <div className="filter-content">
                <div className="tabs fullscreen-content active">
                    <div className="tabs-item">
                        <div
                            className={`brackets ${!isInViewportBracketsArea ? 'scroll' : ''} ${rounds.length < 5 ? 'small' : ''}`}>
                            <SimpleBar
                                className="brackets-inner"
                                style={{ maxWidth: 'unset' }}
                                draggable
                                classNames={{ contentWrapper: 'dragscroll' }}
                                ref={() => {
                                    dragscroll.reset();
                                }}>
                                <div className="brackets-area">
                                    <div className="brackets-row" ref={bracketsAreaRef}>
                                        <div style={{ width: '15px' }} />
                                        {rounds.map((round) => {
                                            return (
                                                <BracketRelegationRound
                                                    key={round.id}
                                                    bracket={currentBracket}
                                                    round={round}
                                                    bracketParticipants={bracketParticipants}
                                                    participantsOptions={participantsOptions}
                                                    canEdit={canEdit}
                                                />
                                            );
                                        })}

                                        {currentRoundId !== 'grand-bracket' && (
                                            <div className="brackets-col">
                                                <div className="brackets-title">
                                                    <div
                                                        className="group-title prop"
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                        }}>
                                                        grand final
                                                    </div>
                                                </div>
                                                <div className="brackets-round">
                                                    <div className="brackets-game">
                                                        <button
                                                            className="brackets-game__btn btn btn--sm"
                                                            onClick={onClickGrandFinalHandler}>
                                                            grand final bracket
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="brackets-title">
                                                    <div className="group-title prop">grand final</div>
                                                </div>
                                            </div>
                                        )}
                                        <div style={{ padding: '0 8px' }} />
                                    </div>
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
