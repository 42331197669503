import React, { useCallback, useRef } from 'react';

import { Empty } from '~app/components/empty';
import { useTournamentsContext } from '~app/contexts';
import { ITournamentRulesModalRef, TournamentRulesModal } from '~app/modals/tournament-rules.modal';
import { nl2br, urlify } from '~app/utils';

interface ITournamentRules {
    canEdit: boolean;
}

export const TournamentRules = ({ canEdit }: ITournamentRules): JSX.Element => {
    const { tournament } = useTournamentsContext();

    const tournamentRulesModalRef = useRef<ITournamentRulesModalRef>(null);

    const onClickEditRulesHandler = useCallback(() => {
        if (tournament !== null) {
            tournamentRulesModalRef.current?.open(tournament);
        }
    }, [tournament]);

    return (
        <section className="section section--main section--sm">
            <div className="section-bg">
                <div className="section-bg__img" style={{ backgroundImage: `url(bg5)` }}></div>
            </div>
            <div className="section-inner">
                <div className="container--sm">
                    {canEdit && (
                        <div className="filter filter--admin">
                            <div className="filter-main">
                                <div className="filter-wrap">
                                    <div className="filter-inner">
                                        <div className="filter-btn">
                                            <button className="btn btn--sm" onClick={onClickEditRulesHandler}>
                                                edit rules
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!!tournament?.rules?.length && (
                        <div className="filter-content">
                            <div className="rules">
                                {tournament.rules.map((rule) => {
                                    return (
                                        <div className="rules-item" key={rule.id}>
                                            <div className="rules-item__title h6">{rule.title}</div>
                                            <div className="rules-item__text">
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: nl2br(urlify(rule.description ?? '')),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {!tournament?.rules?.length && (
                        <div className="filter-content">
                            <Empty />
                        </div>
                    )}

                    {canEdit && (
                        <div className="filter filter--admin">
                            <div className="filter-main">
                                <div className="filter-wrap">
                                    <div className="filter-inner">
                                        <div className="filter-btn">
                                            <button className="btn btn--sm" onClick={onClickEditRulesHandler}>
                                                edit rules
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <TournamentRulesModal ref={tournamentRulesModalRef} />
        </section>
    );
};
