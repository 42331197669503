import dragscroll from 'dragscroll';
import React, { useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';

import { BracketGroupDuel } from '~app/components/tournament-bracket-group/bracket-group-duel';
import { TournamentBracketGroupTable } from '~app/components/tournament-bracket-group/tournament-bracket-group-table';
import { useTournamentsContext } from '~app/contexts';
import { BEST_OF_OPTIONS } from '~app/utils/constants';

import styles from './tournament-bracket-group.module.scss';

interface ITournamentBracketGroupProps {
    canEdit: boolean;
    onClickInsertParticipants: () => void;
}

export const TournamentBracketGroup = ({
    canEdit,
    onClickInsertParticipants,
}: ITournamentBracketGroupProps): JSX.Element | null => {
    const { currentBracket, currentRoundId, tournament } = useTournamentsContext();

    const [view, setView] = useState<'standing' | 'duels'>('standing');

    const group = useMemo(() => {
        if (!currentBracket || !currentRoundId) {
            return null;
        }

        return currentBracket.groups.find((_) => _.id === +currentRoundId) || null;
    }, [currentBracket, currentRoundId]);

    console.log(currentRoundId, group);

    if (!currentBracket || !tournament || !group) {
        return null;
    }

    const totalRounds = currentBracket.participants % 2 ? currentBracket.participants : currentBracket.participants - 1;
    const matchesPerRound = Math.floor(
        currentBracket.participants % 2 ? (currentBracket.participants + 1) / 2 : currentBracket.participants / 2,
    );

    return (
        <div className="container--sm">
            <div className={styles.groupControl}>
                <div
                    className={[
                        styles.groupControl_button,
                        view === 'standing' ? styles.groupControl_button_active : '',
                    ].join(' ')}
                    onClick={() => setView('standing')}>
                    Standing
                </div>
                <div
                    className={[styles.groupControl_button, view === 'duels' && styles.groupControl_button_active].join(
                        ' ',
                    )}
                    onClick={() => setView('duels')}>
                    Matches
                </div>
                <div className={styles.groupControl_divider} />
                {canEdit && (
                    <button className="btn btn--sm" style={{ margin: '0 8px' }} onClick={onClickInsertParticipants}>
                        Insert participants
                    </button>
                )}
                <div className={['select-btn', styles.groupControl_boSelect].join(',')}>
                    <div
                        style={{
                            textAlign: 'right',
                            width: '100%',
                            lineHeight: '59px',
                            padding: '0 16px',
                            background: '#3c3c3c',
                        }}>
                        {BEST_OF_OPTIONS.find((_) => _.value === currentBracket.best_of)?.label}
                    </div>
                </div>
            </div>
            <div className="section-inner">
                <div className="container--sm">
                    <div className="tabs active">
                        <div className="tabs-item">
                            <div className="filter-content filter-content--sm">
                                <div
                                    className="groups"
                                    style={{ display: 'flex', flexWrap: 'wrap', margin: '8px -15px' }}></div>

                                {view === 'standing' && (
                                    <TournamentBracketGroupTable
                                        group={group}
                                        canEdit={canEdit}
                                        bracket={currentBracket}
                                        tournament={tournament}
                                    />
                                )}

                                {view === 'duels' && (
                                    <>
                                        <SimpleBar
                                            className="brackets-inner"
                                            style={{ maxWidth: 'unset' }}
                                            draggable
                                            classNames={{ contentWrapper: 'dragscroll' }}
                                            ref={() => {
                                                dragscroll.reset();
                                            }}>
                                            <div
                                                className={styles.rounds}
                                                style={{
                                                    visibility: view === 'duels' ? 'visible' : 'hidden',
                                                    gridTemplateColumns: `repeat(${totalRounds}, 216px)`,
                                                }}>
                                                {Array.from({ length: totalRounds }, (_, i) => {
                                                    return <div key={i}>Round {i + 1}</div>;
                                                })}
                                            </div>
                                            <div
                                                className={styles.duels}
                                                style={{
                                                    visibility: view === 'duels' ? 'visible' : 'hidden',
                                                    gridTemplateColumns: `repeat(${totalRounds}, 216px)`,
                                                    gridTemplateRows: `repeat(${matchesPerRound}, 1fr)`,
                                                }}>
                                                {group.duels.map((duel) => {
                                                    return (
                                                        <BracketGroupDuel
                                                            key={duel.id}
                                                            duel={duel}
                                                            group={group}
                                                            bracket={currentBracket}
                                                            canEdit={canEdit}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </SimpleBar>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
