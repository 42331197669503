import { requestSecure } from '~app/utils/request';

const BASE_PATH = 'brackets';

export const updateParticipants = async (bracketId: number, participants: number[]): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${bracketId}/swiss/participants`, {
        method: 'POST',
        data: { participants },
    });
};

export const updateGroupDuel = async (
    bracketId: number,
    roundId: number,
    duelId: number,
    data: any,
): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${bracketId}/swiss/rounds/${roundId}/duels/${duelId}`, {
        method: 'POST',
        data,
    });
};

export const generateRound = async (bracketId: number, roundId: number): Promise<{ success: boolean }> => {
    return requestSecure<{ success: boolean }>(`${BASE_PATH}/${bracketId}/swiss/rounds/${roundId}/generate`, {
        method: 'POST',
    });
};
