import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ChampAvatar1 from '~app/assets/img/champ-avatar1.jpg';
import ChampAvatar1Webp from '~app/assets/img/champ-avatar1.webp';
import { IAuthorizeResponse } from '~app/types/authorize.interface';
import { Player } from '~app/types/player.type';
import { ISetting, ISettingsResponse } from '~app/types/setting.interface';
import { IITournamentTypesResponse, ITournamentType } from '~app/types/tournament-type.interface';
import { IUser, UserRole } from '~app/types/user.interface';
import { makeS3ResourceLink, request } from '~app/utils';
import { requestSecure } from '~app/utils/request';

interface RootContextProps {
    isInitiated: boolean;
    init: () => Promise<void>;
    settings: ISetting[] | null;
    settingsDict: { [key: string]: string } | null;
    hasToken: boolean;
    isGuest: boolean;
    isAdmin: boolean;
    isLoaderVisible: boolean;
    login: (email: string, password: string) => Promise<void>;
    twitchLogin: (code: string) => Promise<void>;
    logout: () => void;
    showLoader: () => void;
    hideLoader: () => void;
    tournamentTypes: ITournamentType[] | null;
    tournamentTypesSelectOptions: { value: number; label: string; slug?: string }[];
    getTournamentTypeById: (id: number) => ITournamentType | null;
    getAvatar: (player?: Partial<Player>) => {
        img: any;
        webp?: any;
        defaultImg: any;
    };
    profile: IUser | null;
}

export const RootContext = createContext<RootContextProps>({} as RootContextProps);

export const RootContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();

    const [isInitiated, setInitiated] = useState<boolean>(false);
    const [isLoaderVisible, setLoaderVisibility] = useState<boolean>(true);
    const [tournamentTypes, setTournamentTypes] = useState<ITournamentType[] | null>(null);
    const [tournamentTypesSelectOptions, setTournamentTypesSelectOptions] = useState<
        { value: number; label: string; slug?: string }[]
    >([]);
    const [settings, setSettings] = useState<ISetting[] | null>(null);
    const [settingsDict, setSettingsDict] = useState<{ [key: string]: string } | null>(null);
    const [profile, setProfile] = useState<IUser | null>(null);
    const [hasToken, setHasToken] = useState<boolean>(false);

    const handleRemoveToken = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setProfile(null);
        setHasToken(false);
        navigate('/');
    };

    const fetchProfile = async (): Promise<void> => {
        const profileResponse = await requestSecure<IUser>('/profile', { method: 'GET' });
        setProfile(profileResponse);
    };

    const fetchAuthorize = async (email: string, password: string): Promise<void> => {
        const authInfo = await request<IAuthorizeResponse>('/authorize', {
            method: 'POST',
            data: { email, password },
        });

        localStorage.setItem('access_token', authInfo.access_token);
        localStorage.setItem('refresh_token', authInfo.refresh_token);
        setHasToken(true);

        await fetchProfile();
    };

    const fetchTwitchAuthorize = async (code: string): Promise<void> => {
        const authInfo = await request<IAuthorizeResponse>('/authorize/twitch', {
            method: 'POST',
            data: { code },
        });

        localStorage.setItem('access_token', authInfo.access_token);
        localStorage.setItem('refresh_token', authInfo.refresh_token);
        setHasToken(true);

        fetchProfile().then();
    };

    const fetchSettings = async () => {
        const settingsResp = await request<ISettingsResponse>('/settings');
        if (!Array.isArray(settingsResp)) {
            console.error('Invalid response');

            return;
        }

        setSettings(settingsResp);

        const settingsDictResp = settingsResp.reduce((acc: { [key: string]: string }, item: ISetting) => {
            acc[item.key] = item.value;

            return acc;
        }, {});
        setSettingsDict(settingsDictResp);
    };

    const fetchTournamentTypes = async () => {
        const tournamentTypesResp = await request<IITournamentTypesResponse>('/tournament-types');
        setTournamentTypes(tournamentTypesResp);

        if (tournamentTypesResp) {
            const typeOptions = tournamentTypesResp.map((tType) => {
                let label = tType.name;
                if (tType.id == 1) {
                    label += ' (solo)';
                }
                if (tType.id == 2) {
                    label = '1 vs 1 5x5 (teams)';
                }

                return {
                    value: tType.id,
                    slug: tType.slug,
                    label,
                };
            });

            setTournamentTypesSelectOptions(typeOptions);
        }
    };

    const getTournamentTypeById = (id: number): ITournamentType | null => {
        if (tournamentTypes === null) {
            return null;
        }

        return tournamentTypes.find((_) => _.id === id) ?? null;
    };

    const showLoader = () => {
        setLoaderVisibility(true);
    };

    const hideLoader = () => {
        setLoaderVisibility(false);
    };

    const init = async () => {
        const initQueue = [];
        if (localStorage.getItem('access_token') || localStorage.getItem('refresh_token')) {
            initQueue.push(fetchProfile());
            setHasToken(true);
        }

        initQueue.push(fetchSettings());
        initQueue.push(fetchTournamentTypes());

        await Promise.all(initQueue);
        setInitiated(true);
    };

    const getAvatar = (player?: Partial<Player>) => {
        const defaultAvatar = { defaultImg: ChampAvatar1 };

        if (!player) {
            return { ...defaultAvatar, img: ChampAvatar1, webp: ChampAvatar1Webp };
        }

        if (player.race?.avatar) {
            defaultAvatar.defaultImg = makeS3ResourceLink(player.race.avatar) ?? ChampAvatar1;
        }

        if (player.avatar) {
            return {
                ...defaultAvatar,
                img: player.avatar.toLowerCase().startsWith('http') ? player.avatar : makeS3ResourceLink(player.avatar),
            };
        }

        if (player.race?.avatar) {
            return {
                ...defaultAvatar,
                img: makeS3ResourceLink(player.race.avatar),
            };
        }

        return { ...defaultAvatar, img: ChampAvatar1, webp: ChampAvatar1Webp };
    };

    return (
        <RootContext.Provider
            value={{
                isInitiated,
                init,
                settings,
                settingsDict,
                hasToken: hasToken,
                isGuest: profile === null,
                isLoaderVisible,
                login: fetchAuthorize,
                twitchLogin: fetchTwitchAuthorize,
                logout: handleRemoveToken,
                showLoader,
                hideLoader,
                tournamentTypes,
                tournamentTypesSelectOptions,
                getTournamentTypeById,
                getAvatar,
                profile,
                isAdmin: profile?.role === UserRole.ADMIN || profile?.role === UserRole.ROOT,
            }}>
            {children}
        </RootContext.Provider>
    );
};

export const useRootContext = () => useContext(RootContext);
